@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "DIN Light";
  src: url("../public/assets/fonts/DIN LightAlternate.ttf");
}
@font-face {
  font-family: "DIN Medium";
  src: url("../public/assets/fonts/DIN Medium.otf");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.title {
  color: #FFF;
  font-family: "DIN Medium";
  font-size: 6rem;
  font-style: normal;

}

.header-1 {
  color: #FFF;
  font-family: "DIN Medium";
  font-size: 1.5rem;
  font-style: normal;
  letter-spacing: 0.225rem;
}

.header-2 {
  color: #FFF;
  font-family: "DIN Medium";
  font-size: 1.25rem;
  font-style: normal;
  letter-spacing: 0.1875rem;
}

.header-3 {
  color: #FFF;
  font-family: "DIN Medium";
  font-size: 1rem;
  font-style: normal;
  letter-spacing: 0.15rem;
}

.body-1 {
  color: #FFF;
  font-family: "DIN Light";
  font-size: 1rem;
  font-style: normal;
}

.body-2 {
  color: #FFF;
  font-family: "DIN Light";
  font-size: 0.875rem;
  font-style: normal;
}

.label {
  color: #FFF;
  font-family: "DIN Medium";
  font-size: 0.75rem;
  font-style: normal;
  letter-spacing: 0.1125rem;
}   

.caption {
  color: #FFF;
  font-family: "DIN Medium";
  font-size: 0.75rem;
  font-style: normal;
}