@keyframes flicker {
    0% { opacity: 1; }
    4% { opacity: 0; }
    8% { opacity: 1; }
    12% { opacity: 0; }
    16% { opacity: 1; }
    20% { opacity: 0; }
    24% { opacity: 1; }
    40% { opacity: 0; }
    44% { opacity: 1; }
  }
  
  @keyframes slideIn {
    from { transform: translateX(-100px); opacity: 0; }
    to { transform: translateX(0); opacity: 1; }
  }
  
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
